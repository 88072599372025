import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AdminService } from "src/app/services/admin.service";
import { Customer } from "src/app/services/user.service";
import {
  DEFAULT_PAGE_SIZE,
  Page,
  UtilsService,
} from "src/app/services/utils.service";

@Component({
  selector: "app-customer",
  templateUrl: "./customer.page.html",
  styleUrls: ["./customer.page.scss"],
})
export class CustomerPage implements OnInit {
  page = new Page();
  rows = new Array<Customer>();
  query = "";
  sort: { dir: string; prop: string };

  constructor(
    private activatedRoute: ActivatedRoute,
    private adminService: AdminService,
    public utils: UtilsService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      if (params.page) {
        this.loadCustomerPage({ offset: parseInt(params.page) - 1 });
      } else {
        this.loadCustomerPage({ offset: 0 });
      }
    });
  }

  loadCustomerPage(pageInfo) {
    let currentPage = pageInfo.offset + 1;
    this.adminService
      .getCustomerList(currentPage, DEFAULT_PAGE_SIZE, this.query, this.sort)
      .subscribe({
        next: (res) => {
          if (res.status === "OK") {
            window.history.pushState(
              {},
              "",
              `/#/admin/customers/${currentPage}`
            );
            this.page = {
              pageNumber: pageInfo.offset,
              size: DEFAULT_PAGE_SIZE,
              totalElements: res.totalCount,
              totalPages: Math.ceil(res.totalCount / DEFAULT_PAGE_SIZE),
            };
            this.rows = res.customers;
          } else {
            this.utils.showToast("Chyba na serveru.", "danger");
          }
        },
        error: (err) => {
          this.utils.showToast("Chyba spojení.", "danger");
        },
      });
  }

  filter(event: any) {
    this.query = event.detail.value;
    this.loadCustomerPage({ offset: 0 });
  }

  sortPage(event: any) {
    this.sort = event.sorts[0];
    this.loadCustomerPage({ offset: this.page.pageNumber });
  }

  exportCustomers() {
    this.adminService.exportCustomers(this.query, this.sort).subscribe({
      next: (blob) => {
        const a = document.createElement("a");
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = "customer-export.csv";
        a.click();
        URL.revokeObjectURL(objectUrl);
      },
      error: (err) => {
        this.utils.showToast("Při exportu nastala chyba.", "danger");
      },
    });
  }
}
