import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

export type User = {
  id: string;
  uuid: string;
  username: string;
  name: string;
  surname: string;
  created_at: Date;
  blockedAt?: Date;
  activatedAt: Date;
  expiration?: Date;
  accountID?: string;
  organization?: string;
  roles?: string[];
  groups?: string[];
};

export type Address = {
  country: string;
  city: string;
  address: string;
  zipCode: string;
  representative: string;
};

export type Customer = {
  id: string;
  uuid: string;
  created_at: Date;
  name: string;
  contact: string;
  taxNumber: string;
  address?: Address;
};

export type LicenceQuota = {
  type: number;
  scopes?: string;
  priority?: number;
  hour: number;
  day: number;
  week: number;
  month: number;
  year: number;
  total: number;
};

export type LicenceRestriction = {
  expiration: Date;
  quotas: LicenceQuota[];
};

export type LicenceItem = {
  productID: string;
  releaseIDs: string[];
  restriction: LicenceRestriction;
};

export type Licence = {
  id: string;
  uuid: string;
  customerID: string;
  created: Date;
  active: boolean;
  items: LicenceItem[];
  restriction: LicenceRestriction;
};

export type LinkResponse = {
  link?: string;
  message: string;
  status: string;
};

export type LoginResponse = {
  message: string;
  status: string;
  token?: string;
};

export type ProfileResponse = {
  message: string;
  status: string;
  user?: User;
  customer?: Customer;
};

@Injectable({
  providedIn: "root",
})
export class UserService {
  url = environment.url;

  constructor(private http: HttpClient) {}

  login(email: string, password: string) {
    return this.http.post<LoginResponse>(`${this.url}/login`, {
      email: email,
      password: password,
    });
  }

  register(
    name: string,
    surname: string,
    username: string,
    password: string,
    language: string,
    redirect: string
  ) {
    return this.http.post<LoginResponse>(`${this.url}/register`, {
      name: name,
      surname: surname,
      username: username,
      password: password,
      language: language,
      redirect: redirect,
    });
  }

  activateAccount(email: string, code: string) {
    return this.http.post<LinkResponse>(`${this.url}/activate-account`, {
      code: code,
      email: email,
    });
  }

  requestPasswordChange(
    email: string,
    language: string,
    redirect: string = null
  ) {
    return this.http.post<LinkResponse>(`${this.url}/request-password-change`, {
      email: email,
      language: language,
      redirect: redirect,
    });
  }

  setNewPassword(email: string, code: string, password: string) {
    return this.http.post<LinkResponse>(`${this.url}/set-new-password`, {
      code: code,
      email: email,
      password: password,
    });
  }

  getProfile() {
    return this.http.get<ProfileResponse>(`${this.url}/profile`);
  }

  updateUser(name: string, surname: string) {
    return this.http.post(`${this.url}/update-user`, {
      name: name,
      surname: surname,
    });
  }

  changePassword(oldPassword: string, newPassword: string) {
    return this.http.post(`${this.url}/change-password`, {
      oldPassword: oldPassword,
      newPassword: newPassword,
    });
  }
}
