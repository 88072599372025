import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ToastController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Order } from "./eshop.service";
import { Address } from "./user.service";

export type Country = {
  code: string;
  name: string;
};

export type CountryLang = {
  code: string;
  name_cs: string;
  name_en: string;
  region: string;
  vat_rate: number;
};

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  constructor(
    private toastCtrl: ToastController,
    public translate: TranslateService
  ) {}

  async showToast(message: string, color: string = "dark", duration = 3000) {
    const toast = await this.toastCtrl.create({
      message: message,
      duration: duration,
      position: "middle",
      color: color,
    });
    toast.present();
  }

  stringifyAddress(a?: Address): string {
    if (!a) {
      return "";
    }

    return a.address + ", " + a.zipCode + " " + a.city + ", " + a.country;
  }

  stringifyOrderItems(o: Order): string {
    if (o.items === null) {
      return "";
    }
    return o.items.map((i) => i.name).join(", ");
  }

  /**
   * Checks a form for errors and creates proper error messages.
   * @param form Any form group element.
   * @param namespace Name of the form namespace to be
   * used in translating component names.
   * @returns Not sure yet, maybe the message itself or some object?
   */
  handleFormErrors(form: FormGroup, namespace: string) {
    for (let name in form.controls) {
      let c = form.controls;

      if (c[name] instanceof FormGroup) {
        let g = c[name] as FormGroup;
        this.handleFormErrors(g, namespace + "." + name);
      }

      if (c[name].errors !== null) {
        let errors = c[name].errors;

        for (let type in errors) {
          let message = "";
          let error = errors[type];
          // Translation string for a form field
          let trName = this.translate.instant(`${namespace}.${name}`);
          switch (type) {
            case "required":
              message = this.translate.instant("forms.error.required", {
                name: trName,
              });
              break;
            case "email":
              message = this.translate.instant("forms.error.email", {
                name: trName,
              });
              break;
            case "minlength":
              message = this.translate.instant("forms.error.minlength", {
                name: trName,
                requiredLength: error.requiredLength,
                actualLength: error.actualLength,
              });
              break;
            case "mustMatch":
              message = this.translate.instant("forms.error.mustMatch", {
                name: trName,
                mustMatch: this.translate.instant(`${namespace}.${error}`),
              });
              break;
            case "passwordValidator":
              message = this.translate.instant(
                "forms.error.passwordValidator.base",
                { name: trName }
              );

              error.forEach((e: string) => {
                let params: { actualLength?: number } = {};
                if (e === "length") {
                  params.actualLength = c[name].value.length;
                }

                message += this.translate.instant(
                  `forms.error.passwordValidator.${e}`,
                  params
                );

                message += ", ";
              });

              message = message.substring(0, message.length - 2);
              break;
            default:
              message = this.translate.instant("forms.error.default", {
                name: trName,
              });
              break;
          }

          this.showToast(message, "danger");
          return;
        }
      }
    }
  }

  countrySort(a: Country, b: Country): number {
    let aTranslated: string = this.translate.instant(a.name);
    let bTranslated: string = this.translate.instant(b.name);
    return aTranslated.localeCompare(bTranslated);
  }

  countryLangSort(a: CountryLang, b: CountryLang): number {
    let lang = this.translate.currentLang;
    return a["name_" + lang].localeCompare(b["name_" + lang]);
  }

  async getCountryList(): Promise<CountryLang[]> {
    let res = await fetch("assets/countries/countries.json");
    return await res.json();
  }
}

/**
 * This is a default page size that all datatables
 * use by default.
 */
export const DEFAULT_PAGE_SIZE = 10;

/**
 * An object used to get page information from the server
 */
export class Page {
  // The number of elements in the page
  size: number = 0;
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  pageNumber: number = 0;
}
