import { Injectable } from "@angular/core";
import { CanLoad, Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { UtilsService } from "../services/utils.service";
import { filter, map, take } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ExonAdminGuard implements CanLoad {
  constructor(
    private auth: AuthService,
    private router: Router,
    private utils: UtilsService
  ) {}

  canLoad() {
    return this.auth.authenticationState.pipe(
      filter((val) => val !== null),
      take(1),
      map((isLoggedIn) => {
        if (!isLoggedIn) {
          this.router.navigateByUrl("/login");
          this.utils.showToast(
            "You have to login to access this page.",
            "warning"
          );
          return false;
        }

        if (!this.auth.isAdmin()) {
          this.router.navigateByUrl("/login");
          this.utils.showToast("No access.", "danger");
          return false;
        }

        return true;
      })
    );
  }
}
