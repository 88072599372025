import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Customer, Licence, LoginResponse, User } from "./user.service";

// Number of requests per time period
export const REQUEST_NUM = 1;
// Size of a single request/incoming data
export const SINGLE_REQUEST_DATA_SIZE_MB = 2;
// Size of requests/incoming data per time period
export const REQUEST_DATA_SIZE_MB = 3;
// Size of stored data
export const DATA_SIZE_MB = 4;

export type CustomerListResponse = {
  message: string;
  status: string;
  customers?: Customer[];
  totalCount: number;
};

export type AccountsForSelectResponse = {
  message: string;
  status: string;
  accounts?: {
    accountID: string;
    label: string;
  }[];
};

export type LicenceTemplateListResponse = {
  message: string;
  status: string;
  licenceTemplates: string[];
};

export type CustomerDetailResponse = {
  message: string;
  status: string;
  customer?: Customer;
  licence?: Licence;
};

/**
 * Response object that doesn't carry more data
 * than status and message info.
 */
export type SimpleResponse = {
  message: string;
  status: string;
};

export type UserListResponse = {
  message: string;
  status: string;
  users?: User[];
  totalCount: number;
};

/**
 * Response object for user's detailed data.
 */
export type UserDetailResponse = {
  message: string;
  status: string;
  user?: User;
};

@Injectable({
  providedIn: "root",
})
export class AdminService {
  constructor(private http: HttpClient) {}

  getCustomerList(
    page: number,
    pageSize: number = 10,
    query: string,
    sort: { dir: string; prop: string }
  ) {
    let skip = (page - 1) * pageSize;
    return this.http.post<CustomerListResponse>(
      `${environment.url}/customers/${pageSize}/${skip}`,
      {
        query: query,
        sort: sort,
      }
    );
  }

  getAccountListForSelect(query: string) {
    return this.http.post<AccountsForSelectResponse>(
      `${environment.url}/account-list`,
      { query: query }
    );
  }

  getLicenceTemplateList() {
    return this.http.get<LicenceTemplateListResponse>(
      `${environment.url}/licence-templates`
    );
  }

  getCustomerDetail(customerID: string) {
    return this.http.get<CustomerDetailResponse>(
      `${environment.url}/customer/${customerID}`
    );
  }

  updateCustomer(customer: Customer, customerID: string) {
    return this.http.post<SimpleResponse>(
      `${environment.url}/update-customer/${customerID}`,
      {
        customer: customer,
      }
    );
  }

  createCustomer(customer: Customer, licenceTemplate: string) {
    return this.http.post<SimpleResponse>(
      `${environment.url}/create-customer`,
      {
        customer: customer,
        licenceTemplate: licenceTemplate,
      }
    );
  }

  getUserList(
    page: number,
    pageSize: number = 10,
    query: string,
    sort: { dir: string; prop: string }
  ) {
    let skip = (page - 1) * pageSize;
    return this.http.post<UserListResponse>(
      `${environment.url}/users/${pageSize}/${skip}`,
      {
        query: query,
        sort: sort,
      }
    );
  }

  getUserDetail(userID: string) {
    return this.http.get<UserDetailResponse>(
      `${environment.url}/user/${userID}`
    );
  }

  updateUser(user: User, userID: string) {
    return this.http.post<SimpleResponse>(
      `${environment.url}/update-user/${userID}`,
      {
        user: user,
      }
    );
  }

  createUser(user: User, password: string, accountID: string) {
    return this.http.post<SimpleResponse>(`${environment.url}/create-user`, {
      user: user,
      password: password,
      accountID: accountID,
    });
  }

  updateLicence(licence: Licence, licenceID: string) {
    return this.http.post<SimpleResponse>(
      `${environment.url}/update-licence/${licenceID}`,
      {
        licence: licence,
      }
    );
  }

  exportCustomers(query: string, sort: { dir: string; prop: string }) {
    return this.http.post(
      `${environment.url}/export-customers`,
      {
        query: query,
        sort: sort,
      },
      {
        responseType: "blob",
      }
    );
  }

  exportUsers(query: string, sort: { dir: string; prop: string }) {
    return this.http.post(
      `${environment.url}/export-users`,
      {
        query: query,
        sort: sort,
      },
      {
        responseType: "blob",
      }
    );
  }

  updateApiCapability(capability: string, add: boolean) {
    return this.http.post<SimpleResponse>(
      `${environment.url}/update-api-capibility`,
      {
        capability: capability,
        add: add,
      }
    );
  }

  loginAsUser(email: string) {
    return this.http.post<LoginResponse>(`${environment.url}/login-as-user`, {
      email: email,
    });
  }
}
