import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "./services/auth.service";
import { UtilsService } from "./services/utils.service";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  pages: {
    title: string;
    url: string;
    icon: string;
    indent?: boolean;
  }[] = [];

  isAdminLayout = false;

  public guestPages = [
    {
      title: "appComponent.login",
      url: "/login",
      icon: "log-in",
    },
    {
      title: "appComponent.register",
      url: "/register",
      icon: "person-add",
    },
    {
      title: "appComponent.language",
      url: "/language",
      icon: "language",
    },
  ];

  public exonAdminPages = [
    {
      title: "Zákazníci",
      url: "/admin/customers",
      icon: "storefront",
    },
    {
      title: "Uživatelé",
      url: "/admin/users",
      icon: "people",
    },
    {
      title: "Nový zákazník",
      url: "/admin/customer/new",
      icon: "add",
    },
    {
      title: "Nový uživatel",
      url: "/admin/user/new",
      icon: "add",
    },
    {
      title: "API",
      url: "/admin/api",
      icon: "code-slash",
    },
  ];

  constructor(
    private auth: AuthService,
    private router: Router,
    private translate: TranslateService,
    private utils: UtilsService
  ) {}

  async ngOnInit() {
    // Checks if a jwt token is present and if it's expired or not.
    // If it's present but expired, it removes it.
    let isLoggedIn = await this.auth.checkToken();

    this.router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationEnd) {
          if (event.url.startsWith("/admin")) {
            this.isAdminLayout = true;
            this.pages = this.exonAdminPages;
          } else {
            this.isAdminLayout = false;
            this.pages = this.guestPages;
          }
        }
      },
    });
  }

  isLoggedIn() {
    return this.auth.isAuthenticated();
  }

  logout() {
    this.auth.logout();
    this.router.navigateByUrl("/login");
    this.utils.showToast(
      this.translate.instant("appComponent.logoutSuccess"),
      "success",
      1500
    );
  }
}
