import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { IonicStorageModule } from "@ionic/storage-angular";
import { JwtModule, JWT_OPTIONS } from "@auth0/angular-jwt";
import { StorageService } from "./services/storage.service";
import { environment } from "src/environments/environment";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { LanguageService } from "./services/language.service";
import defaultLanguage from "src/assets/i18n/en.json";
import {
  ColumnChangesService,
  DimensionsHelper,
  ScrollbarHelper,
} from "@swimlane/ngx-datatable";

/**
 * This is the first thing that runs when the app is being initialized.
 * It runs before app.component.ts code. It can be used to initialize
 * storage, languages and other important features.
 *
 * Ensure there are no circular dependencies.
 * @param storageService Ionic storage service
 * @param languageService Language service
 * @returns
 */
function initApp(
  storageService: StorageService,
  langugeService: LanguageService
) {
  return () =>
    new Promise(async (resolve, reject) => {
      await storageService.init();
      await langugeService.initialize(defaultLanguage);
      resolve(true);
    });
}

export function jwtOptionsFactory(storageService: StorageService) {
  return {
    tokenGetter: (request) => {
      if (request.url.includes("assets")) {
        // Auth header causes issues on GCB with files from the asset folder.
        return null;
      } else {
        return storageService.get("auth_token");
      }
    },
    allowedDomains: environment.allowedDomains,
  };
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [StorageService],
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      multi: true,
      deps: [StorageService, LanguageService],
    },
    ScrollbarHelper,
    DimensionsHelper,
    ColumnChangesService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
