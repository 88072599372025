import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./guards/auth.guard";
import { ExonAdminGuard } from "./guards/exon-admin.guard";
import { CustomerPage } from "./pages/admin/customer/customer.page";

const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/login/login.module").then((m) => m.LoginPageModule),
  },
  {
    path: "register",
    loadChildren: () =>
      import("./pages/register/register.module").then(
        (m) => m.RegisterPageModule
      ),
  },
  {
    path: "activate-account/:email/:code",
    loadChildren: () =>
      import("./pages/activate-account/activate-account.module").then(
        (m) => m.ActivateAccountPageModule
      ),
  },
  {
    path: "forgotten-password",
    loadChildren: () =>
      import("./pages/forgotten-password/forgotten-password.module").then(
        (m) => m.ForgottenPasswordPageModule
      ),
  },
  {
    path: "new-password/:email/:code",
    loadChildren: () =>
      import("./pages/new-password/new-password.module").then(
        (m) => m.NewPasswordPageModule
      ),
  },
  {
    path: "profile",
    loadChildren: () =>
      import("./pages/profile/profile.module").then((m) => m.ProfilePageModule),
    canLoad: [AuthGuard],
  },
  {
    path: "eshop",
    loadChildren: () =>
      import("./eshop/eshop.module").then((m) => m.EshopPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: "orders",
    loadChildren: () =>
      import("./pages/orders/orders.module").then((m) => m.OrdersPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: "orders/:orderID",
    loadChildren: () =>
      import("./pages/orders/orders.module").then((m) => m.OrdersPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: "usage",
    loadChildren: () =>
      import("./pages/usage/usage.module").then((m) => m.UsagePageModule),
    canLoad: [AuthGuard],
  },
  {
    path: "admin/customers",
    loadChildren: () =>
      import("./pages/admin/customer/customer.module").then(
        (m) => m.CustomerPageModule
      ),
    canLoad: [ExonAdminGuard],
  },
  {
    path: "admin/customers/:page",
    component: CustomerPage,
    pathMatch: "full",
    canLoad: [ExonAdminGuard],
  },
  {
    path: "admin/customer/detail/:id",
    loadChildren: () =>
      import("./pages/admin/customer-detail/customer-detail.module").then(
        (m) => m.CustomerDetailPageModule
      ),
    canLoad: [ExonAdminGuard],
  },
  {
    path: "admin/customer/new",
    loadChildren: () =>
      import("./pages/admin/new-customer/new-customer.module").then(
        (m) => m.NewCustomerPageModule
      ),
    canLoad: [ExonAdminGuard],
  },
  {
    path: "admin/users",
    loadChildren: () =>
      import("./pages/admin/user/user.module").then((m) => m.UserPageModule),
    canLoad: [ExonAdminGuard],
  },
  {
    path: "admin/users/:page",
    loadChildren: () =>
      import("./pages/admin/user/user.module").then((m) => m.UserPageModule),
    canLoad: [ExonAdminGuard],
  },
  {
    path: "admin/user/detail/:id",
    loadChildren: () =>
      import("./pages/admin/user-detail/user-detail.module").then(
        (m) => m.UserDetailPageModule
      ),
    canLoad: [ExonAdminGuard],
  },
  {
    path: "admin/user/new",
    loadChildren: () =>
      import("./pages/admin/new-user/new-user.module").then(
        (m) => m.NewUserPageModule
      ),
    canLoad: [ExonAdminGuard],
  },
  {
    path: "admin/api",
    loadChildren: () =>
      import("./pages/admin/api/api.module").then((m) => m.ApiPageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: "reload",
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
