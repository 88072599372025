import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  constructor(
    private storage: StorageService,
    private translate: TranslateService
  ) {}

  /** Set language based on the user settings or browser configuration. */
  async initialize(defaultLanguage: Object) {
    this.translate.setTranslation("en", defaultLanguage);
    this.translate.setDefaultLang("en");
    // Check whether the language has been previously set by the user
    const storedLang = await this.storage.get("language");
    if (!storedLang) {
      // If not, try to set language regarding to browser setting
      let browserLang = this.translate.getBrowserLang();
      if (this.getLanguages().some((lang) => lang.value == browserLang)) {
        this.translate.use(browserLang);
      } else {
        // If browser language is not supported in the app, use english as default
        this.translate.use("en");
      }
    } else {
      this.translate.use(storedLang);
    }
  }

  async setLanguage(language: string) {
    this.translate.use(language);
    await this.storage.set("language", language);
  }

  getCurrentLanguage(): string {
    return this.translate.currentLang;
  }

  getLanguages() {
    return [
      { text: "Česky", value: "cs" },
      { text: "English", value: "en" },
    ];
  }
}
