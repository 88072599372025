import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { UserService } from "./user.service";
import { StorageService } from "./storage.service";
import { tap } from "rxjs/operators";

export type DecodedToken = {
  exp: number;
  iat: number;
  is_exon_admin?: boolean;
};

@Injectable({
  providedIn: "root",
})
export class AuthService {
  authenticationState = new BehaviorSubject<boolean>(null);
  url = environment.url;
  decodedToken: DecodedToken;

  constructor(
    public http: HttpClient,
    private helper: JwtHelperService,
    private storageService: StorageService,
    private userService: UserService
  ) {}

  checkToken() {
    return this.storageService.get("auth_token").then((token: string) => {
      if (token !== null && !this.helper.isTokenExpired(token)) {
        this.decodedToken = this.helper.decodeToken(token);
        this.authenticationState.next(true);
        return true;
      }

      this.storageService.remove("auth_token");
      return false;
    });
  }

  login(email: string, password: string) {
    return this.userService.login(email, password).pipe(
      tap((res) => {
        if (res.token) {
          this.decodedToken = this.helper.decodeToken(res.token);
          this.saveToken(res.token);
          this.authenticationState.next(true);
        }
      })
    );
  }

  logout() {
    this.storageService.remove("auth_token").then(() => {
      this.authenticationState.next(false);
    });
  }

  isAuthenticated(): boolean {
    return this.authenticationState.value;
  }

  saveToken(token: string) {
    return this.storageService.set("auth_token", token);
  }

  isAdmin() {
    return this.decodedToken.is_exon_admin === true;
  }
}
